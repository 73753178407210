<template>
  <div class="lesson">
    <div class="video">
      <router-link :to="{ name: 'Lesson', query: { v: lesson.id } }">
        <img class="previewLessonImg" :src="displayCoverUrl" alt="" />
      </router-link>
    </div>
    <div class="desc">
      <h2 class="title">
        <router-link :to="{ name: 'Lesson', query: { v: lesson.id } }">
          {{ lesson.title[lang] }}
        </router-link>
        <template v-if="isAdmin">
          <router-link
            :to="{ name: 'EditLessons', query: { v: lesson.id } }"
            class="action-icon ml-2"
          >
            <i class="fas fa-edit" />
          </router-link>
          <span class="action-icon warning-icon" @click="deleteLesson">
            <i class="fas fa-trash-alt" />
          </span>
        </template>
      </h2>
      <div class="author">
        {{ `Instructor: ${lesson.instructor_name}` }}
      </div>
      <div class="content">{{ lesson.description[lang] }}</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import role from "@/mixins/role.js";
import lessonsApi from "@/apis/lessons";

export default {
  props: {
    lesson: {
      type: Object,
      default: () => ({})
    }
  },
  mixins: [role],
  computed: {
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    lang() {
      return this.$store.getters["user/langShortValue"];
    },
    isAfterPremiereDate() {
      return moment(new Date()).isAfter(this.lesson.premiere_date);
    },
    displayCoverUrl() {
      const previewUrlKey = this.lesson.preview_video_url.replace(
        "https://www.youtube.com/embed/",
        ""
      );
      if (previewUrlKey === "") {
        return this.lesson.default_cover;
      }
      return this.isAfterPremiereDate ? this.lesson.cover : this.lesson.preview_cover;
    }
  },
  methods: {
    async deleteLesson() {
      try {
        await lessonsApi.deleteLesson(this.lesson.id);
        this.$message.success(this.$t("message.delete_success"));
        window.location.reload();
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.lesson {
  display: flex;
  margin-bottom: 40px;

  .video {
    width: 50%;
    padding-right: 20px;
    position: relative;

    .youtubeIframe {
      width: 100%;
    }

    .previewLessonImg {
      width: 100%;
    }
  }

  .desc {
    flex: 1;
    .title {
      color: var(--themeColor);
      font-size: 26px;
      font-weight: 700;
      line-height: 1.2;
      margin-bottom: 4px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
    }

    .author {
      color: #a4a4a4;
      font-size: 24px;
      margin-top: 10px;
    }

    .content {
      margin-top: 28px;
      font-size: 18px;
      word-wrap: break-word;
      white-space: pre-wrap;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      overflow: hidden;
      -webkit-box-orient: vertical;
    }
  }
}

@media all and (max-width: 1200px) {
  .lesson {
    flex-direction: column;
    margin-bottom: 40px;
    /* height: 500px; */
    .video {
      width: 100%;
      padding-right: 0px;
    }

    .desc {
      .title {
        color: var(--themeColor);
        margin-top: 18px;
        font-size: 20px;
      }
      .author {
        font-size: 18px;
        margin-top: 0px;
      }
      .content {
        display: none;
      }
    }
  }
}
</style>
