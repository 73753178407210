<template>
  <div :key="lessonPageKey" class="container">
    <Lesson v-if="isDetailPage" :lessonId="$route.query.v" />
    <template v-else>
      <PageTitle marginBottom>
        Free TOEFL Video Lessons
        <template v-if="isAdmin">
          <router-link :to="{ name: 'CreateLessons' }">
            <i class="fas fa-plus action-icon" />
          </router-link>
        </template>
      </PageTitle>
      <LessonCard :key="lesson.id" v-for="lesson in lessons" :lesson="lesson" />
    </template>
  </div>
</template>

<script>
import role from "@/mixins/role.js";
import PageTitle from "@/components/PageTitle.vue";
import Lesson from "@/views/toefl/lessons/Lesson";
import LessonCard from "@/views/toefl/lessons/LessonCard";
import lessonsApi from "@/apis/lessons";

export default {
  metaInfo() {
    return {
      title: "Lessons - " + this.CompanyName
    };
  },
  mixins: [role],
  components: {
    PageTitle,
    Lesson,
    LessonCard
  },
  computed: {
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    isDetailPage() {
      return this.$route.query.v;
    },
    lessonPageKey() {
      return window.location.href;
    },
    lesson() {
      return this.lessons.find(({ id }) => id === Number(this.$route.query.v));
    }
  },
  data() {
    return {
      lessons: []
    };
  },
  created() {
    if (!this.isDetailPage) {
      this.fetchLessons();
    }
  },
  methods: {
    async fetchLessons() {
      const { lessons } = await lessonsApi.getLessons();
      this.lessons = [...lessons];
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding-top: 40px;
}
</style>
