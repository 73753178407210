<template>
  <div v-if="lesson">
    <PageTitle marginBottom>
      {{ lesson.title[lang] }}
      <template v-if="isAdmin">
        <router-link
          :to="{ name: 'EditLessons', query: { v: lesson.id } }"
          class="action-icon ml-2"
        >
          <i class="fas fa-edit" />
        </router-link>
        <span class="action-icon warning-icon" @click="deleteLesson">
          <i class="fas fa-trash-alt" />
        </span>
      </template>
    </PageTitle>
    <div class="videoContainer">
      <template v-if="lesson.preview_video_url_key">
        <iframe
          v-if="isAfterPremiereDate ? lesson.video_url_key : lesson.preview_video_url_key"
          :src="isAfterPremiereDate ? lesson.video_url : lesson.preview_video_url"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
        <img
          v-else
          :src="isAfterPremiereDate ? lesson.cover : lesson.preview_cover"
        />
      </template>
      <img v-else :src="lesson.default_cover" />
    </div>
    <div class="author">
      {{
        `${moment(lesson.premiere_date).format("MMM DD, YYYY")}． 
         Instructor: ${lesson.instructor_name}`
      }}
    </div>
    <div class="description">
      {{ lesson.description[lang] }}
    </div>
  </div>
</template>

<script>
import role from "@/mixins/role.js";
import moment from "moment";
import PageTitle from "@/components/PageTitle.vue";
import lessonsApi from "@/apis/lessons";

export default {
  metaInfo() {
    return {
      title: `${this.lesson?.title[this.lang] || ""} - ` + this.CompanyName
    };
  },
  mixins: [role],
  props: {
    lessonId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      lesson: null
    };
  },
  components: {
    PageTitle
  },
  computed: {
    lang() {
      return this.$store.getters["user/langShortValue"];
    },
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    moment() {
      return moment;
    },
    isAfterPremiereDate() {
      return moment(new Date()).isAfter(this.lesson.premiere_date);
    }
  },
  async created() {
    const { lesson } = await lessonsApi.getLesson(this.lessonId);
    this.lesson = {
      ...lesson,
      preview_video_url_key: lesson.preview_video_url.replace("https://www.youtube.com/embed/", ""),
      video_url_key: lesson.video_url.replace("https://www.youtube.com/embed/", "")
    };
  },
  methods: {
    async deleteLesson() {
      try {
        await lessonsApi.deleteLesson(this.lessonId);
        this.$message.success(this.$t("message.delete_success"));
        this.$router.push({ name: "Lessons" });
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  margin-top: -4px;
  margin-bottom: 10px;
}

.videoContainer {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.videoContainer iframe, .video-container object, .video-container embed, .videoContainer img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.author {
  margin: 16px 0px;
  font-size: 1.4rem;
  color: #a4a4a4;
}

.description {
  margin-bottom: 40px;
  font-size: 1.4rem;
}
</style>
